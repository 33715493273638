import { Icon } from "@iconify/react/dist/iconify.js";
import WebpImage from "../WebpImage";
import Container from "../Grid/Container";
import Row from "../Grid/Row";
import Column from "../Grid/Column";

import { register } from 'swiper/element/bundle';
import { Fragment, useEffect, useRef, useState } from "react";
import { SwiperRef } from "swiper/react";
import Swiper from "swiper";
import useMediaQuery from "@/Hooks/useMediaQuery";
register();

const getFormat = (filename: string) => {
    return filename.split('.').pop();
};

interface Slide {
    image: string;
    heading: string;
    text: string;
    buttonLink: string;
    buttonText: string;
}

const HeroSlider = ({ slides }: { slides?: Slide[] }) => {
    return (
        <div>
            {slides && slides.length == 1 &&
                <SingleSlideVariant slide={slides[0]} />
            }
            {slides && slides.length > 1 &&
                <ManySlidesVariant slides={slides} />
            }
        </div>
    )
}

const SingleSlideVariant = ({ slide }: { slide: Slide }) => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    const scrollBottom = () => {
        if (wrapperRef.current && typeof window != undefined) {
            window.scrollTo({
                top: wrapperRef.current.getBoundingClientRect().height,
                behavior: "smooth"
            })
        }
    }

    return (
        <div className="lg:mb-[150px] mb-0 minMenu:mt-[170px] lgMb:mt-[165px]  maxMd:mt-[165px]" ref={wrapperRef}>
            <div className="h-full relative">
                {(slide.heading && slide.text && slide.buttonLink && slide.buttonText) &&
                    <div className="lg:absolute static lg:py-0 py-8 lg:h-full lg:top-0 bottom-0 lg:w-1/3 w-full lg:bg-primary/60 bg-primary lg:pl-14 pl-4 pr-4 lg:flex hidden flex-col justify-center">
                        {slide.heading &&
                            <p className="text-h2 leading-h2 text-white font-bold">{slide.heading}</p>
                        }
                        {slide.text &&
                            <p className="text-p leading-p text-white lg:mt-8 mt-4">{slide.text}</p>
                        }
                        {slide.buttonLink &&
                            <a href={slide.buttonLink} className="button-primary bg-secondary border-secondary max-w-[300px] lg:mt-8 mt-4">{slide.buttonText}</a>
                        }
                    </div>
                }
                <picture>
                    <source
                        srcSet={`${slide.image.replace(/\.[^.]+$/, '')}.webp`}
                        type="image/webp"
                    />
                    <source
                        srcSet={`${slide.image.replace(/\.[^.]+$/, '')}.${getFormat(slide.image)}`}
                        type={`image/${getFormat(slide.image)}`}
                    />
                    <img className="w-full object-contain h-auto maxMd:mt-[165px]" src={slide.image} alt="Hero Kusocińskiego" />
                </picture>
                {(slide.heading && slide.buttonLink && slide.buttonText) &&
                    <div className="static lg:py-0 py-8 w-full lg:bg-primary/60 bg-primary lg:pl-14 pl-4 pr-4 flex lg:hidden flex-col justify-center">
                        {slide.heading &&
                            <p className="text-h2 leading-h2 text-white font-bold">{slide.heading}</p>
                        }
                        {slide.text &&
                            <p className="text-p leading-p text-white lg:mt-8 mt-4">{slide.text}</p>
                        }
                        {slide.buttonLink &&
                            <a href={slide.buttonLink} className="button-primary bg-secondary border-secondary max-w-[300px] lg:mt-8 mt-4">{slide.buttonText}</a>
                        }
                    </div>
                }
                <Container className="lg:block hidden">
                    <Row className="justify-between">
                        <Column xl={8}>
                        </Column>
                        <Column xl={4} className="text-right">
                            <button type="button" className="glass-style px-3 py-6 -mt-12 rounded-[60px] 3xl:-mr-5 mr-10" onClick={() => scrollBottom()} title="Zjedź w dół">
                                <Icon icon={`oui:arrow-down`} className="text-primary" width={40} height={40} />
                            </button>
                        </Column>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

const ManySlidesVariant = ({ slides }: { slides: Slide[] }) => {
    const swiperRef = useRef<HTMLDivElement>(null);
    const [realIndex, setRealIndex] = useState<number>(1);
    const isMobile = useMediaQuery()

    useEffect(() => {
        // @ts-ignore
        if (slides) {
            const swiperContainer = swiperRef.current;
            const params = {
                slidesPerView: 1,
                grabCursor: false,
                speed: 750,
                loop: true,
                autoHeight: false,
                navigation: {
                    prevEl: '.controlled-swiper-prev-slide',
                    nextEl: '.controlled-swiper-next-slide',
                },
                on: {
                    realIndexChange: (swiper: Swiper) => {
                        setRealIndex(swiper.realIndex + 1);
                    },
                },
            };
            // @ts-ignore
            Object.assign(swiperContainer, params);
            // @ts-ignore
            swiperContainer.initialize();
        }
    }, [swiperRef])

    const scrollBottom = () => {
        if (swiperRef.current && typeof window != undefined) {
            window.scrollTo({
                top: swiperRef.current.getBoundingClientRect().height,
                behavior: "smooth"
            })
        }
    }

    return (
        <div className="lg:mb-[150px] mb-0 minMenu:mt-[170px] lgMb:mt-[165px] lg:h-[calc(100vh_-_200px)] maxMd:mt-[165px]">
            {/* @ts-ignore */}
            <swiper-container ref={swiperRef} init="false" style={{ height: '100%' }}>
                {slides.map((slide, i) => (
                    <Fragment key={i + slide.image}>
                        {/* @ts-ignore */}
                        <swiper-slide>
                            {(slide.heading && slide.text && slide.buttonLink && slide.buttonText) &&
                                <div className="lg:absolute static lg:py-0 py-8 lg:h-full lg:top-0 bottom-0 lg:w-1/3 w-full lg:bg-primary/60 bg-primary lg:pl-14 pl-4 pr-4 lg:flex hidden flex-col justify-center">
                                    {slide.heading &&
                                        <p className="text-h2 leading-h2 text-white font-bold">{slide.heading}</p>
                                    }
                                    {slide.text &&
                                        <p className="text-p leading-p text-white lg:mt-8 mt-4">{slide.text}</p>
                                    }
                                    {slide.buttonLink &&
                                        <a href={slide.buttonLink} className="button-primary bg-secondary border-secondary max-w-[300px] lg:mt-8 mt-4">{slide.buttonText}</a>
                                    }
                                </div>
                            }
                            <picture>
                                <source
                                    srcSet={`${slide.image.replace(/\.[^.]+$/, '')}.webp`}
                                    type="image/webp"
                                />
                                <source
                                    srcSet={`${slide.image.replace(/\.[^.]+$/, '')}.${getFormat(slide.image)}`}
                                    type={`image/${getFormat(slide.image)}`}
                                />
                                <img className="w-full object-cover h-full object-bottom" src={slide.image} alt="Hero Kusocińskiego" />
                            </picture>
                            {(slide.heading && slide.buttonLink && slide.buttonText) &&
                                <div className="static lg:py-0 py-8 w-full lg:bg-primary/60 bg-primary lg:pl-14 pl-4 pr-4 flex lg:hidden flex-col justify-center">
                                    {slide.heading &&
                                        <p className="text-h2 leading-h2 text-white font-bold">{slide.heading}</p>
                                    }
                                    {slide.text &&
                                        <p className="text-p leading-p text-white lg:mt-8 mt-4">{slide.text}</p>
                                    }
                                    {slide.buttonLink &&
                                        <a href={slide.buttonLink} className="button-primary bg-secondary border-secondary max-w-[300px] lg:mt-8 mt-4">{slide.buttonText}</a>
                                    }
                                </div>
                            }
                            {/* @ts-ignore */}
                        </swiper-slide>
                    </Fragment>
                ))}
                {/* @ts-ignore */}
            </swiper-container>
            <Container className="hidden lg:block">
                <Row className="justify-between">
                    <Column xl={8}>
                        <div className="flex -mt-8 gap-x-4 maxMd:mt-0">
                            <button type="button" className="glass-style px-6 py-3 rounded-[60px] relative z-20 controlled-swiper-prev-slide maxMd:hidden">
                                <Icon icon={`oui:arrow-left`} className="text-primary" width={40} height={40} />
                            </button>
                            <div className="flex relative z-20 gap-x-6 self-center">
                                {[...Array(slides.length)].map((_, i) => (
                                    <div key={i} className={`${realIndex == i + 1 ? 'bg-primary' : 'bg-spot'} w-3 h-3 rounded-full transition-all ease-in-out duration-200`}>
                                    </div>
                                ))}
                            </div>
                            <button type="button" className="glass-style px-6 py-3 rounded-[60px] relative z-20 controlled-swiper-next-slide maxMd:hidden">
                                <Icon icon={`oui:arrow-right`} className="text-primary" width={40} height={40} />
                            </button>
                        </div>
                    </Column>
                    <Column xl={4} className="text-right maxMd:hidden">
                        <button type="button" className="glass-style px-3 py-6 -mt-12 rounded-[60px] -mr-5 relative z-20 mb:mr-0" onClick={() => scrollBottom()} title="Zjedź w dół">
                            <Icon icon={`oui:arrow-down`} className="text-primary" width={40} height={40} />
                        </button>
                    </Column>
                </Row>
            </Container>
            <div className="maxMd:block hidden">
                <div className="flex relative z-20 gap-x-6 self-center justify-center bg-primary pb-5 pt-8">
                    {[...Array(slides.length)].map((_, i) => (
                        <div key={i} className={`${realIndex == i + 1 ? 'bg-spot' : 'bg-secondary'} w-3 h-3 rounded-full transition-all ease-in-out duration-200`}>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default HeroSlider;