import { AdvPopupType } from "@/types/components/advPopupType";
import Modal from "../Modal";
import { PropsWithChildren, SVGProps, memo, useEffect, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Link } from "@inertiajs/react";

const AdvPopup = ({ expireAt, externalLink, link, photo }: AdvPopupType) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        if (expireAt) {
            const expireAtDate = new Date(expireAt);
            const currentDate = new Date();

            if (expireAtDate > currentDate) {
                setIsOpen(true);

            }
        }
    }, [expireAt])

    const LineMdClose = (props: SVGProps<SVGSVGElement>) => {
        return (<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" {...props}><path fill="none" stroke="currentColor" strokeDasharray={12} strokeDashoffset={12} strokeLinecap="round" strokeWidth={2} d="M12 12L19 19M12 12L5 5M12 12L5 19M12 12L19 5"><animate fill="freeze" attributeName="stroke-dashoffset" dur="1s" values="12;0"></animate></path></svg>);
    }

    const AdvPopupLink = ({ externalLink, link, children }: PropsWithChildren & AdvPopupType) => {
        if (externalLink && link) {
            return (
                <a className="cursor-pointer" href={link} target="_blank" rel="noopener noreferrer" title={`Przejdź do strony ${link}`}>
                    {children}
                </a>
            )
        }
        if (!externalLink && link) {
            return (
                <Link href={link} className="cursor-pointer" title={`Przejdź do podstrony ${link}`}>
                    {children}
                </Link>
            )
        }
    }

    return (
        <div>
            <Modal
                show={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <div className="relative">
                    <button onClick={() => setIsOpen(false)} className="absolute z-10 -top-8 md:-right-8 -right-3 bg-white rounded-full flex justify-center items-center md:p-3 p-1 outline-none text-primary border-[4px] border-primary">
                        <LineMdClose />
                    </button>

                    <AdvPopupLink externalLink={externalLink} link={link}>
                        <img src={photo} alt="Zdjęcie" />
                    </AdvPopupLink>
                </div>
            </Modal>
        </div>
    )
}

export default memo(AdvPopup);